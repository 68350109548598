import { getDefaultPaymentPlans } from '@listening/shared';
import {
  VITE_STRIPE_MONTHLY_12_ID,
  VITE_STRIPE_MONTHLY_15_ID,
  VITE_STRIPE_YEARLY_119_ID,
  VITE_STRIPE_YEARLY_69_ID,
  VITE_STRIPE_YEARLY_99_ID,
} from 'astro:env/client';

export const defaultPaymentPlans = getDefaultPaymentPlans({
  VITE_STRIPE_MONTHLY_12_ID,
  VITE_STRIPE_MONTHLY_15_ID,
  VITE_STRIPE_YEARLY_119_ID,
  VITE_STRIPE_YEARLY_69_ID,
  VITE_STRIPE_YEARLY_99_ID,
});
