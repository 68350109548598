import { t } from '@/i18n/translation_util';

import { defaultPaymentPlans } from '@/services/pricing-service';
import { trialDays } from '@listening/shared';

export function PricingLabel1() {
  return (
    <div className="text-center text-[16px] leading-[21px] text-[#4f4f4f] sm:text-[20px] sm:leading-[27px]">
      <div
        dangerouslySetInnerHTML={{
          __html: t(
            'Try unlimited listening. After trialDays its priceMonthly or priceYearly',
            {
              trialDays: trialDays,
              priceMonthly: defaultPaymentPlans.monthly.amount,
              priceYearly: defaultPaymentPlans.yearly.amount,
            },
          ),
        }}
      />
    </div>
  );
}
